import type { StandardField } from '../../../fields';
import getRegexByField from '../../get-regex-by-field';
import type { Options, ValidRegex } from '../types';
import getRepeatOperator from './get-repeat-operator';
import testValidity from './test-validity';
import transformErrorsToFixedRegexAfterValids from './transform-errors-to-fixed-regex-after-valids';

const NUMBER_OF_VALID_REGEXES_TO_CONSIDER_PREVIOUS_FIELDS_AS_FIXED = 3;

const TIMEOUT_IN_MS =
  typeof process !== 'undefined' && process?.env?.CI ? 500 : 20;
const TIMEOUT_ERROR_COUNT = 1;

const errorStateRegExp = /\(\.\*\?\)(\(\))*$/;

const stopTesting = (
  start: number,
  options: Options,
  currentRegexes: ValidRegex[],
  index: number,
) => {
  if (
    options.instantFail &&
    currentRegexes[index - 1] &&
    !currentRegexes[index - 1][1]
  ) {
    return true;
  }

  const time = Date.now() - start;
  const errorCount = currentRegexes.filter(([, valid]) => !valid).length;

  const usedTimeout = options.customTimeout || TIMEOUT_IN_MS;

  if (time > usedTimeout && errorCount >= TIMEOUT_ERROR_COUNT) {
    options.instantFail = true;

    return true;
  }

  return false;
};

export default (fields: StandardField[], text: string, options: Options) => {
  const start = Date.now();

  // eslint-disable-next-line complexity
  return (
    currentRegexes: ValidRegex[],
    field: StandardField,
    index: number,
  ): ValidRegex[] => {
    if (stopTesting(start, options, currentRegexes, index)) {
      return [...currentRegexes, ['()', false]];
    }

    const newRegex = `${getRegexByField(field, fields[index + 1], true)}`;

    const repeatOperator = getRepeatOperator(options);
    const getInvalidRegEx = (
      currentRegexes: ValidRegex[],
      nextField?: StandardField,
    ) => {
      if (
        nextField?.type === 'fixed' &&
        nextField.value.length === 1 &&
        !options.strictDelimiter &&
        !options.instantFail
      ) {
        return `([^${nextField.value[0]}]${repeatOperator})`;
      }

      if (
        errorStateRegExp.test(
          `^${currentRegexes.map(([regex]) => regex).join('')}`,
        )
      ) {
        return '()';
      }

      return `(.${repeatOperator})`;
    };

    const regexAsString = `^${currentRegexes.map(([regex]) => regex).join('')}`;
    const matches = new RegExp(regexAsString).exec(text)?.slice(1) || [];

    // we dont need to optimize if it is the last one
    const newCurrentRegexes =
      index === fields.length - 1
        ? currentRegexes
        : transformErrorsToFixedRegexAfterValids(
            fields,
            currentRegexes,
            matches,
            NUMBER_OF_VALID_REGEXES_TO_CONSIDER_PREVIOUS_FIELDS_AS_FIXED,
          );

    const [valid, newNewCurrentRegexes, optimizedNewRegex] = testValidity(
      fields,
      text,
      field,
      index,
      newRegex,
      newCurrentRegexes,
      options,
    );

    // optimization to fast fail when the fixed value is not found anymore
    if (
      newRegex !== optimizedNewRegex &&
      optimizedNewRegex === '()' &&
      field.type === 'fixed' &&
      field.required
    ) {
      options.instantFail = true;
    }

    const lastRegex = newNewCurrentRegexes.pop();

    // first one
    if (!lastRegex) {
      return [
        [
          valid
            ? optimizedNewRegex
            : getInvalidRegEx(newNewCurrentRegexes, fields[index + 1]),
          valid,
        ],
      ];
    }

    return [
      ...newNewCurrentRegexes,
      // if last one was valid, we use it
      // or if last one was empty (multiple repeated errors), we use it
      // else we'll use a catch all, and possibly replace [^...]*
      // because we are merging the previous catchall with another catchall
      [
        lastRegex[1]
          ? lastRegex[0]
          : lastRegex[0] === '()'
            ? '()'
            : `(.${repeatOperator})`,
        lastRegex[1],
      ],
      [
        valid
          ? optimizedNewRegex
          : (lastRegex[1] &&
              getInvalidRegEx(
                [...newNewCurrentRegexes, lastRegex],
                fields[index + 1],
              )) ||
            '()',
        valid,
      ],
    ];
  };
};
