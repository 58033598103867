import { useMutation } from '@tanstack/vue-query';

import type { TaxonomyField } from '@grasp-gg/lib-core-taxonomies';

import { applyTaxoFieldConditionalFormattingAt } from '@grasp-gg/extension-excel/services/taxo/field-validation/conditional-formatting';
import { toRange } from '@grasp-gg/extension-excel/utils/excel/address';
import {
  setRows,
  upsertAlias,
} from '@grasp-gg/extension-excel/utils/excel/caching-table';
import { fillWith } from '@grasp-gg/extension-excel/utils/excel/range';

import { fieldColumnAlias } from '@grasp-gg/extension-excel/services/taxo/field-validation/validations/utils';
import {
  asTaxoFieldFormattedValue,
  asTaxoFieldValidation,
  encodeTaxoFieldValidationParameters,
} from './serialization';

export function applyTaxoFieldValidation() {
  return useMutation({
    mutationFn: async (data: {
      field: TaxonomyField;
      range: string;
      invalidValueAllowed: boolean;
      invalidValueHighlighted: boolean;
    }) => {
      await cacheTaxoValidation({
        field: data.field,
      });

      const validation = asTaxoFieldValidation({
        field: data.field,
        invalidValueAllowed: data.invalidValueAllowed,
      });

      if (!validation)
        throw new Error(
          `Field type ${data.field.type} is not yet supported for data validation.`,
        );

      const format = asTaxoFieldFormattedValue({ field: data.field });

      await Excel.run(async (context) => {
        const range = toRange(context, data.range);

        range.dataValidation.set(validation);

        if (format) {
          range.load({
            rowCount: true,
            columnCount: true,
          });

          await context.sync();

          range.numberFormat = fillWith(range, format);
        }

        if (data.invalidValueHighlighted) {
          await applyTaxoFieldConditionalFormattingAt({
            field: data.field,
            at: range,
          });
        }

        await context.sync();
      });
    },
  });
}

export function clearTaxoFieldValidation() {
  return useMutation({
    mutationFn: async (data: {
      range: string;
    }) => {
      await Excel.run(async (context) => {
        const range = toRange(context, data.range);

        range.dataValidation.clear();
        range.conditionalFormats.clearAll();

        range.load({
          rowCount: true,
          columnCount: true,
        });

        await context.sync();

        range.numberFormat = fillWith(range, 'General');

        await context.sync();
      });
    },
  });
}

export async function cacheTaxoValidation(options: {
  field: TaxonomyField;
}) {
  await setRows({
    table: '_gg_txo_val_cache',
    column: options.field.id,
    values: encodeTaxoFieldValidationParameters(options.field).map(String),
  });

  if (options.field.type === 'text' || options.field.type === 'anything') {
    await upsertAlias({
      name: fieldColumnAlias(options.field.id),
      table: '_gg_txo_val_cache',
      column: options.field.id,
    });
  }
}
