import type { TaxonomyFieldCheckbox } from '@grasp-gg/lib-core-taxonomies';

import { fieldColumn } from './utils';

export function asDataValidation(field: TaxonomyFieldCheckbox) {
  return {
    list: {
      inCellDropDown: true,
      source: `=${fieldColumn(field.id)}`,
    },
  } satisfies Excel.DataValidationRule;
}

export function encodeParameters(field: TaxonomyFieldCheckbox) {
  if (!field.parameters.keyValues) return ['yes', 'no'];

  return field.parameters.keyValues.map(({ value }) => value);
}
