import type { TaxonomyFieldCheckbox } from '@grasp-gg/lib-core-taxonomies';

import { fieldColumn } from '../validations/utils';
import { asSignedFormula, self } from './utils';

export function conditionFormula(field: TaxonomyFieldCheckbox): string {
  return asSignedFormula({
    signature: field.id,
    formula: `NOT(
      COUNTIF(
        ${fieldColumn(field.id)},
        ${self()}
      ) > 0
    )`,
  });
}
